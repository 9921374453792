.modal {
  background-color: transparent;
  .modal-info {
    margin-top: 10% !important;
    @media only screen and (max-width: 500px) {
      margin-top: 50% !important;
    }
  }
  .modal-dialog {

    .modal-height {
      width: 100%;
      height: 570px;
      @media only screen and (max-width: 500px) {
        max-width: 414px;
        height: 640px;
      }
      .modal-body {
        padding: 38px;
        @media only screen and (max-width: 500px) {
          padding: 0 8% 35px !important;
        }
        [type="checkbox"]:not(:checked), [type="checkbox"]:checked {
          position: relative;
          opacity: 1;
          pointer-events: auto;
        }
        .form-modal-title {
          font-family: var(--font-family);
          font-weight: 600;
          font-size: 25px;
          letter-spacing: -0.05em;
          color: #fff;
          @media only screen and (max-width: 500px) {
            margin-top: 60px;
            font-size: 22px;
            color: #fff;
          }
        }
        .form-about {
          position: relative;
          padding-top: 22%;
          @media only screen and (max-width: 500px) {
            maxf-width: 100%;
            padding-top: 32%;
          }
          .accordion-position {
            top: 5% !important;
            @media only screen and (max-width: 500px) {
              top: 5% !important;
              max-width: 100% !important;
            }
          }
          .form-control {
            @media only screen and (max-width: 500px) {
              max-width: 100% !important;
            }
          }
          .modal-form-info {
            @media only screen and (max-width: 500px) {
              transform: translateY(10%);
              text-indent: 15px;
            }
          }
          .modal-form-phoneNumber {
            @media only screen and (max-width: 500px) {
              margin-top: 31px;
              text-indent: 15px;
            }
          }
          .modal-form-textarea {
            width: 427px !important;
            @media only screen and (max-width: 500px) {
              max-width: 100% !important;
              height: 130px;
              margin-top: 31px;
            }
          }
        }
      }
    }
    .modal-content {

    }
  }
}

