.partnors {
  width: 100%;
  height: auto !important;
  row-gap: 20px;
}

.player__wrapper {
  position: relative;
  height: auto;
}

.control-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  flex-direction: column;
  z-index: 1;
  display: flex;
  justify-content: space-between;
}

.control-container.visible {
  transition: all ease-in-out 0.5s;
  opacity: 1;
}

.control-container.hidden {
  opacity: 0;
  display: none;
}

.top_container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 24px 30px;

  .fullscreen-icon {
    width: 49px;
    height: 49px;

    border: 0.67px solid;
    border-image-source: linear-gradient(
      202deg,
      rgba(255, 255, 255, 0.2) 8%,
      rgba(255, 255, 255, 0) 85%
    );
    box-shadow: -0px 0px 0px -0px #ffffff59 inset;
    background: #00000040;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fullscreen-icon img {
    width: 24px;
    height: 24px;
  }
}

.mid__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .icon__btn {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    border: 0.67px solid;
    border-image-source: linear-gradient(
      202deg,
      rgba(255, 255, 255, 0.2) 8%,
      rgba(255, 255, 255, 0) 85%
    );
    box-shadow: -0px 0px 0px -0px #ffffff59 inset;
    background: #00000040;
    border-radius: 50%;
  }

  .play__btn {
    width: 96px;
    height: 96px;
  }
}

.bottom__container {
  padding-left: 15px;
  padding-right: 15px;

  ._bottom__container {
    width: 100%;
    height: 100%;
    padding-bottom: 25px;
  }

  .bottom__progressbar {
    width: 100%;
    height: 8px;
    border-radius: 20px;
    overflow: hidden;
    background: rgba(217, 217, 217, 0.4);
    position: relative;
    cursor: pointer;
  }

  .bottom-progressbar-filled {
    position: absolute;
    background: #ffffff;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    //transition: all ease-in-out 0s;
  }
}

@media (max-width: 800px) {
  .top_container {
    padding: 10px;
  }
  .fullscreen-icon {
    width: 30px !important;
    height: 30px !important;
  }

  .fullscreen-icon img {
    width: 20px !important;
    height: 20px !important ;
  }

  .icon__btn {
    width: 40px !important;
    height: 40px !important;
  }

  .icon__btn img {
    width: 20px !important;
    height: 20px !important;
  }
}

.__card {
  position: relative;
  height: max-content;

  a {
    color: #ffffff;
  }

  .card__text {
    font-family: Montserrat;
    background: rgba(0, 0, 0, 0.15);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    font-size: 33px;
    line-height: 65px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: rgb(0, 0, 0);
      background: linear-gradient(
        160deg,
        rgba(0, 0, 0, 1) 10%,
        rgba(0, 212, 255, 0) 100%
      );
    }
  }
}

@media only screen and (max-width: 500px) {
  .card__text {
    display: none !important;
  }
}
