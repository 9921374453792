.menu-navbar {
  display: none;
  @media only screen and (max-width: 500px) {
    position: absolute;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 70%;
    background-color: #000000BF;
    padding: 0;
    z-index: 9999999;
    transition: 1s;
    display: block;
    .menu-collapse {
      display: flex;
      justify-content: flex-start;
      margin-top: 50%;
      margin-left: 20px;
      .close-button {
        position: absolute;
        top: 6%;
        left: 10px
      }
      .navbar-nav {
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        .nav-link {
          color: #fff !important;
          text-decoration: none;
          font-size: 10px !important;
        }

        .nav-link:hover {
          border: none !important;
        }

      }
      .changeLanguage {
        position: absolute;
        top: 6%;
        right: 25px;
        max-width: 90px;
        height: 22px;
        stroke-width: 0.806px;
        stroke: #FFF;
        display: flex;
        justify-content: space-around;
        background-size: inherit;

        .rus {
          margin: 1.6px 0;
        }

        .uzb {
          margin-right: 3.55px;
        }

        .eng {
          margin-right: 3.55px;
        }
        .rus,.uzb,.eng {
          font-family: Montserrat;
          font-size: 9px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.69px;
          padding-bottom: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
        }
        .language-button {
          width: 20px;
          height: 20px;
          margin-left: 4.5%;
        }
      }
      .contact {
        position: absolute;
        bottom: 15%;
        right: 5%;
        .footer-socials {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          margin-top: 18px;
          @media only screen and (max-width: 500px) {

          }
          img {
            width: 93.602px;
            height: 88.232px;
            border: 1.472px solid #FFF;
            background: radial-gradient(107.32% 141.42% at 0% 0%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
            box-shadow: -3.68px -3.68px 184.005px 0px rgba(255, 255, 255, 0.02) inset;
            backdrop-filter: blur(15.456403732299805px);
            padding: 17px;
            border-radius: 24px;
            @media only screen and (max-width: 500px) {
              width: 32px;
              height: 32px;
              padding: 3px;
              border-radius: 7px;
            }
          }
          img:not(first-child){
            margin-left: 22px;
          }
        }
        .footer-contacts {
          color: #FFF;
          text-align: right;
          font-family: Montserrat;
          font-size: 30px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
          margin-top: 100px;
          @media only screen and (max-width: 500px) {
            margin-top: 20px;
            font-size: 9px;
          }
          .phone-number,.address {
            border-radius: 31.098px;
            border: 1.244px solid #FFF;
            background: radial-gradient(107.32% 141.42% at 0% 0%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
            box-shadow: -3.11px -3.11px 155.488px 0px rgba(255, 255, 255, 0.02) inset;
            backdrop-filter: blur(13.06098461151123px);
            display: flex;
            align-items: center;
            justify-content: space-around;
            @media only screen and (max-width: 500px) {

            }
            img {
              width: 30.446px;
              height: 30.446px;
              @media only screen and (max-width: 500px) {
                width: 8px;
                height: 8px;
              }
            }
          }
          .phone-number {
            width: 323.533px;
            height: 50.376px;
            @media only screen and (max-width: 500px) {
              width: 100px;
              height: 25px;
            }
          }
          .address {
            width: 572.446px;
            height: 50.376px;
            @media only screen and (max-width: 500px) {
              width: 176px;
              height: 27px;
            }
            img {

            }
          }
        }
      }
      h6 {
        display: none;
        bottom: 3%;
        right: 20%;
        text-align: center !important;
        color: #FFF;
        font-family: Montserrat;
        font-size: 7px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  };

}

.active {
  color: #d5a251 !important;
}

@media only screen and (max-width: 500px) {
  .activeTab {
    right: -0%;
    transition: 1s;
  }
  .hide {
  }
}


@media only screen and (max-width: 500px) {
  .changeLanguage {
    .rus{

    }
    .uzb {

    }
    .eng {

    }
  }
}
@media only screen and (max-width: 500px) {
  .menu-navbar-overlay{
    width: 100%;
    position: absolute;
    height: 100vh;
    background-color: transparent;
    top: 0;
  }
}

