.modal {
  min-height: 100vh !important;

  .modal-dialog {
    margin-top: 10px !important;
    margin-bottom: 0;

    @media only screen and (max-width: 500px) {
      z-index: 1;
      position: relative;
    }

    .modal-content {
      width: 527px;
      background: rgba(0, 0, 0, 1);
      position: relative;
      padding: 0;
      border-radius: 27px;

      @media only screen and (max-width: 500px) {
        max-width: 100%;
        padding: 0;
        margin: 0 auto;
      }

      .application-body {
        padding: 44px 44px 0;

        @media only screen and (max-width: 500px) {
          padding: 20px 15px 0 !important;
        }

        .form-modal-title {
          color: var(--icon-color, #fff);
          font-family: Montserrat;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -1.292px;

          @media only screen and (max-width: 500px) {
            font-size: 20px;
            margin-bottom: 30px;
          }
        }

        .modal-absolute {
          margin-top: 0 !important;

          @media only screen and (max-width: 500px) {
            margin-top: 0 !important;
            transform: translateY(10%);
          }
        }

        .modal-form-textarea {
          width: 100% !important;
          text-indent: 0 !important;

          @media only screen and (max-width: 500px) {
            width: 100% !important;
            text-indent: 0 !important;
          }
        }

        form {
          padding-top: 20%;
        }

        form {
          position: relative;

          [type='checkbox']:not(:checked),
          [type='checkbox']:checked {
            position: relative;
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }
  }
}

.active {
  display: block;
  transition: 1s;
}

.d-grid {
  display: flex;
  justify-content: center;
}

.modal-form-btn {
  color: var(--icon-color, #fff);
  font-family: Montserrat;
  font-size: 24.117px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.206px;
  background-image: url('../../../images/partnership button.svg');
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
}

.MuiPaper-root {
  position: relative;
  z-index: 9;
}

.modal-form-info {
  position: relative;
  z-index: 1;
  max-width: 100% !important;
  height: 44.297px;
  border-radius: 11.435px;
}

.modal-form-textarea {
  height: 130px;
  max-width: 100% !important;
  display: flex;
  align-items: flex-start;
  border-radius: 11.435px;
}

.modal-form-textarea::placeholder {
  color: #b0b0b0;
  font-family: Montserrat;
  font-size: 17.471px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.874px;
}

.modal-form-info::placeholder {
  color: #b0b0b0;
  font-family: Montserrat;
  font-size: 17.471px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.874px;
}

.modal-form-info,
.modal-form-phoneNumber,
.modal-form-company,
.modal-form-email {
  text-indent: 15px;
}

.form-control {
  margin-bottom: 25px !important;
  font-family: Montserrat;
  font-size: 17.471px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.874px;
  border-radius: 11.4px !important;
  background-color: #fff !important;

  //padding-left: 15px !important;
  @media only screen and (max-width: 500px) {
    max-width: 100% !important;
    margin-bottom: 10px !important;
  }
}

.form-check-input {
  box-shadow: none !important;
  border-color: black;
  outline: none;
}

.form-check-input:focus {
  border-color: black;
  outline: none;
  position: relative;
  z-index: 99;
}

.MuiAccordionSummary-expandIconWrapper {
  border: 2px solid #b0b0b0;
  border-radius: 50%;
  font-size: 10px;
}

//.css-1c35hjw-MuiPaper-root-MuiAccordion-root:first-of-type {
//
//}

.MuiPaper-root {
  border-radius: 11.435px !important;
  margin: 0 0 20px 0 !important;
  position: absolute !important;
  top: 5% !important;
  width: 100% !important;

  @media only screen and (max-width: 500px) {
    top: 0% !important;
    width: 100% !important;
  }
}

.css-1wz20g3 {
  border-radius: 11.4px !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  box-shadow: 1.969px -2.461px 16.931px -5.414px rgba(0, 0, 0, 0.49) !important;
}

.form-check-label {
  font-family: Montserrat;
  font-weight: 500;
  color: #000 !important;
}

.MuiSvgIcon-root {
  width: 18px;
  height: 18px;
}

.form-check-input:checked {
  background-color: black;
  border-color: black;
  outline: none;
  box-shadow: none !important;
}

.list-group {
}

.MuiAccordionSummary-content {
  color: #b0b0b0;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.874px;
}

.list-group-item {
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}

.modal-form-phoneNumber::placeholder,
.modal-form-info::placeholder {
  padding-left: 0px;
  //margin-left: 35px;
  //transform: translateX(15px);
}

.exit-btn {
  position: absolute;
  top: 3%;
  right: 3%;
  cursor: pointer;
  width: 26px !important;
  height: 14px !important;

  @media only screen and (max-width: 500px) {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    width: 42px !important;
    height: 23px !important;
    top: 7%;
  }
}
