//background: radial-gradient(107.32% 141.42% at 0% 0%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
//box-shadow: -4.167px -4.167px 208.333px 0px rgba(255, 255, 255, 0.02) inset;
//backdrop-filter: blur(17.5px);

#root {
  height: 100% !important;
}

.title {
  color: #fff;
  font-family: Montserrat;
  font-size: 5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media only screen and (max-width: 500px) {
    padding: 0 20px 0 10px;
    font-size: 24px;
    letter-spacing: -1px;
    margin: 0;
  }
}

.qwerty {
  padding: 20px !important;
  border-radius: 38.264px;
  border: 1.531px solid #fff;
  background: radial-gradient(107.32% 141.42% at 0% 0%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%);
  box-shadow: -3.826px -3.826px 191.319px 0px rgba(255, 255, 255, 0.02) inset;
  backdrop-filter: blur(16.07076644897461px);
}

.vacancies {
  font-family: Montserrat;
  color: #fff;

  .header {
    background: linear-gradient(rgba(0, 0, 0, 0.644), rgba(0, 0, 0, 0.63)),
      url(../images/vancies-header-img.png) no-repeat center center/cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    min-height: 100vh;
    padding: 0 98px;
    width: 100%;
    background-color: #000000;

    @media only screen and (max-width: 500px) {
      padding: 1px 36px;
      height: 100vh;
      background-size: cover;
    }

    .header-title {
      font-weight: 600;
      letter-spacing: -0.05em;
      text-align: center;

      h1 {
        font-size: 146px;
        margin-top: 70px;

        @media only screen and (max-width: 500px) {
          font-size: 76px;
          display: flex;
          flex-direction: column;
          margin-top: 75%;
        }

        span {
          @media only screen and (max-width: 500px) {
            display: flex;
            justify-content: space-between;
          }
        }
      }

      p {
        font-size: 32px;
        transform: translateY(-30px);
        background: radial-gradient(107.32% 141.42% at 0% 0%,
            rgba(255, 255, 255, 0.4) 0%,
            rgba(255, 255, 255, 0) 100%);
        box-shadow: -4.167px -4.167px 208.333px 0px rgba(255, 255, 255, 0.02) inset;
        backdrop-filter: blur(17.5px);
        border-radius: 27px;
        width: 653px;
        height: 47px;
        margin: 0 auto;

        @media only screen and (max-width: 500px) {
          width: 340px;
          height: 77px;
          font-size: 17px;
          transform: translateY(-15px);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px;
          margin-top: 10%;
        }
      }
    }

    .header-subtitle {
      font-weight: 600;
      font-size: 21px;
      letter-spacing: -0.05px;
      text-align: center;
      width: 815px;
      margin: 0 auto;
      transform: translateY(60%);

      @media only screen and (max-width: 1024px) {
        width: 90%;
      }

      @media only screen and (max-width: 500px) {
        font-size: 18px;
        max-width: 100%;
        text-align: center;
      }
    }
  }

  .vacancies-type {
    width: 100%;
    padding-bottom: 200px !important;
    gap: 30px;
    padding: 0 98px;
    background-color: #000;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
      padding: 0 10px;
    }

    .vacancies-marketing {
      width: 22%; // Около 25% ширины, учитывая промежутки (gap)
      height: auto;
      font-weight: 600;
      letter-spacing: -0.05em;
      overflow: hidden;
      padding: 10px;

      @media only screen and (max-width: 1200px) {
        width: 31%; // 3 карточки в ряд на средних экранах
      }

      @media only screen and (max-width: 900px) {
        width: 48%; // 2 карточки в ряд на меньших экранах
      }

      @media only screen and (max-width: 600px) {
        width: 100%; // 1 карточка в ряд на маленьких экранах
        padding-bottom: 40px;
      }

      .marketing-title {
        h2 {
          display: flex;
          flex-direction: column;
          font-size: 20px;
          padding-top: 50px;

          @media only screen and (max-width: 600px) {
            font-size: 18.5px;
            padding-top: 30px;
          }

          span {
            display: flex;
            align-items: center;

            @media only screen and (max-width: 600px) {
              font-size: 23px;
            }

            img {
              width: 92px;
              height: 54px;

              @media only screen and (max-width: 600px) {
                width: 34px;
                height: 18px;
              }
            }
          }
        }
      }

      .marketing-subtitle {
        margin: 30px 0 65px;

        @media only screen and (max-width: 500px) {
          margin: 17px 0 25px;
        }

        p {
          font-size: 21px;

          @media only screen and (max-width: 500px) {
            font-size: 15.5px !important;
          }
        }
      }

      .marketing-vacancy {
        width: 100%;
        height: auto;
        border-radius: 50px;

        @media only screen and (max-width: 500px) {
          height: 251px;
          background-size: cover;
        }

        .marketing-info {
          display: none !important;
          width: 243px;
          height: 53px;
          background: radial-gradient(107.32% 141.42% at 0% 0%,
              rgba(255, 255, 255, 0.4) 0%,
              rgba(255, 255, 255, 0) 100%);
          box-shadow: -3.68px -3.68px 184.005px 0px rgba(255, 255, 255, 0.02) inset;
          backdrop-filter: blur(15.456403732299805px);
          border-radius: 32px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding-left: 15px;
          margin-left: 30px;
          transform: translateY(30px);
          position: absolute;
          z-index: 3;

          @media only screen and (max-width: 500px) {
            width: 177px;
            height: 43px;
            padding-left: 10px;
            margin-left: 7px;
            transform: translateY(26px);
          }

          p {
            font-size: 15px;
            margin: 0;

            @media only screen and (max-width: 500px) {
              font-size: 11px;
            }
          }
        }

        .marketing-btn {
          width: 380px;
          height: 80px;
          background: radial-gradient(107.32% 141.42% at 0% 0%,
              rgba(255, 255, 255, 0.4) 0%,
              rgba(255, 255, 255, 0) 100%);
          box-shadow: -3.68px -3.68px 184.005px 0px rgba(255, 255, 255, 0.02) inset;
          backdrop-filter: blur(15.456403732299805px);
          border-radius: 32px;
          margin: 0 auto;
          transform: translateY(50%);
          position: relative;
          z-index: 3;

          @media only screen and (max-width: 500px) {
            width: 190px;
            height: 60px;
            border-radius: 16px;
            transform: translateY(345%);
          }

          span {
            cursor: pointer;
            font-size: 18px;
            display: flex;
            justify-content: space-between;
            padding: 17px 10px;

            @media only screen and (max-width: 500px) {
              font-size: 15px;
              padding: 8px 6px;
            }

            img {
              background-image: url("../images/footer-arrow-backgound.png");
              background-repeat: no-repeat;
              background-size: inherit;
              background-position: center;
              border-radius: 50%;
              width: 60px;
              height: 60px;
              padding: 10px;

              @media only screen and (max-width: 500px) {
                width: 42px;
                height: 42px;
                padding: 7px;
              }
            }
          }
        }

        .finance-vacancy {
          background-image: url("../images/finance-vacancy.png");
        }
      }


      .marketing-subtitle {
        margin: 30px 0 65px;

        .bg-marketing {
          width: 100%;
          border-radius: 20px;
        }

        @media (min-width: 700px) {
          .bg-marketing {
            width: 100%;
            height: 150px;
          }
        }

        @media only screen and (max-width: 500px) {
          margin: 17px 0 25px;
        }

        p {
          font-size: 21px;

          @media only screen and (max-width: 500px) {
            font-size: 13px;
          }
        }
      }

      .marketing-vacancy {
        width: 100%;
        height: auto;
        border-radius: 50px;
        // position: relative;

        @media only screen and (max-width: 500px) {
          background-size: cover;
        }

        .marketing-info {
          display: none !important;
          width: 243px;
          height: 53px;
          background: radial-gradient(107.32% 141.42% at 0% 0%,
              rgba(255, 255, 255, 0.4) 0%,
              rgba(255, 255, 255, 0) 100%);
          box-shadow: -3.68px -3.68px 184.005px 0px rgba(255, 255, 255, 0.02) inset;
          backdrop-filter: blur(15.456403732299805px);
          border-radius: 32px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding-left: 15px;
          margin-left: 30px;
          transform: translateY(30px);
          position: absolute;
          z-index: 3;

          @media only screen and (max-width: 500px) {
            width: 177px;
            height: 43px;
            padding-left: 10px;
            margin-left: 7px;
            transform: translateY(26px);
          }

          p {
            font-size: 15px;
            margin: 0;

            @media only screen and (max-width: 500px) {
              font-size: 11px;
            }
          }
        }

        .marketing-btn {
          width: 230px;
          // position: absolute !important;
          // bottom: 0;
          height: 80px;
          background: radial-gradient(107.32% 141.42% at 0% 0%,
              rgba(255, 255, 255, 0.4) 0%,
              rgba(255, 255, 255, 0) 100%);
          box-shadow: -3.68px -3.68px 184.005px 0px rgba(255, 255, 255, 0.02) inset;
          backdrop-filter: blur(15.456403732299805px);
          border-radius: 32px;
          margin: 0 auto;
          transform: translateY(50%);
          position: relative;
          z-index: 3;

          @media only screen and (max-width: 500px) {
            width: 190px;
            height: 60px;
            border-radius: 16px;
            // margin: 30px auto;
            // transform: translateY(345%);
          }

          span {
            cursor: pointer;
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            padding: 17px 10px;

            @media only screen and (max-width: 500px) {
              font-size: 12px;
              padding: 10px 6px;
            }

            img {
              background-image: url("../images/footer-arrow-backgound.png");
              background-repeat: no-repeat;
              background-size: inherit;
              background-position: center;
              border-radius: 50%;
              width: 60px;
              height: 60px;
              margin-top: -7px;
              padding: 10px;

              @media only screen and (max-width: 500px) {
                width: 42px;
                height: 42px;
                padding: 7px;
                margin-top: 0px;
              }
            }
          }
        }
      }

      .finance-vacancy {
        background-image: url("../images/finance-vacancy.png");
      }
    }
  }

  .main {
    padding: 0 98px;
    background-image: url("../images/vacancies-main-img.png");

    @media only screen and (max-width: 500px) {
      padding: 0 36px;
    }

    .vacancies-marketing {
      font-weight: 600;
      letter-spacing: -0.05em;

      @media only screen and (max-width: 500px) {
        padding-bottom: 70px;
      }

      .marketing-title {
        h2 {
          display: flex;
          flex-direction: column;
          font-size: 54px;
          padding-top: 50px;

          @media only screen and (max-width: 500px) {
            font-size: 18.5px;
          }

          span {
            display: flex;
            align-items: center;

            img {
              width: 102px;
              height: 54px;

              @media only screen and (max-width: 500px) {
                width: 34px;
                height: 18px;
              }
            }
          }
        }
      }

      .marketing-subtitle {
        margin: 30px 0 65px;

        @media only screen and (max-width: 500px) {
          margin: 17px 0 25px;
        }

        p {
          font-size: 21px;

          @media only screen and (max-width: 500px) {
            font-size: 13px;
          }
        }
      }


      .accountant-vacancy {
        background-image: url("../images/accountant-vacancy.png");
      }
    }

    .main-title {
      text-align: center;
      margin-top: 90px;

      @media only screen and (max-width: 500px) {
        margin-top: 40px;
      }

      p {
        font-size: 54px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 500px) {
          font-size: 15px;
        }

        img {
          height: 257px;
          height: 48px;
          margin-left: 14px;
          text-align: center;

          @media only screen and (max-width: 500px) {
            width: 101px;
            height: 19px;
          }
        }
      }

      h2 {
        font-size: 116px;
        transform: translateY(-40px);

        @media only screen and (max-width: 500px) {
          font-size: 45px;
          transform: translateY(-20px);
        }
      }
    }

    .row {
      margin: 0;
      padding: 0;

      .col-lg-8 {
        img {
          width: 100%;
          height: 558px;

          @media only screen and (max-width: 500px) {
            height: 246px;
            margin-bottom: 19px;
          }
        }
      }

      .col-lg-4 {
        .col-lg-12 {
          img {
            width: 100%;
            height: 270px;

            @media only screen and (max-width: 500px) {
              height: 246px;
            }
          }
        }
      }
    }

    .main-about {
      p {
        font-size: 32px;
        max-width: 954px;
        text-align: center;
        margin: 92px auto 0;
        padding-bottom: 44px;

        @media only screen and (max-width: 500px) {
          font-size: 11px;
          max-width: 342px;
          margin: 32px auto 0;
          padding-bottom: 28px;
        }
      }
    }
  }
}


@media (max-width: 500px) {
  .hidden-vac-navbar {
    margin-left: -11%;
    top: 0%;
  }

  .hidden-vac-navbar.scrolled {
    background-color: black;
    margin-left: -11%;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
}