@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");

@font-face {
  font-family: Suissie Intl;
  src: url("../fonts/Suisse Intl/SuisseIntl-Book.otf");
}

#root {
  overflow: hidden;
}



.home_screen {
  width: 100%;
  height: auto;
  background-image: url("../images/home_screen.png");
  background-position-x: 400px;
  background-size: cover;
  background-repeat: no-repeat;
}

.partners--grid {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 2px solid #fff;
  background: #fff;
  box-shadow: -5px -5px 250px 0px rgba(255, 255, 255, 0.02) inset;
  backdrop-filter: blur(21px);
  padding: 20px;

  img {
    width: auto !important;
    max-width: 100% !important;
    height: 100px;
    border-radius: 10px;
  }
}

.partners--grid2 {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 2px solid #fff;
  background: #fff;
  box-shadow: -5px -5px 250px 0px rgba(255, 255, 255, 0.02) inset;
  backdrop-filter: blur(21px);
  padding: 20px;

  img {
    width: auto !important;
    max-width: 100% !important;
    height: 100px;
    border-radius: 10px;
  }
}

.home {
  margin: 0;
  overflow: hidden;

  .animatedHeader {
    background-position-x: 0px !important;
    transition: 1s;
  }

  .header {
    margin: 0;
    box-sizing: border-box;
    background-color: #000;
    max-width: 100%;
    background-image: url("../images/header-background-2.jpg");
    height: 110vh;
    background-position-x: 400px;
    background-size: cover;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;



    @media only screen and (max-width: 500px) {
      height: 98vh;
      background-position: center !important;
      background-size: cover;
      width: 100%;
    }

    .header-info {
      margin-top: auto;
      margin-bottom: auto;

      @media (max-width: 850px) {
        margin-top: 50px;
      }

      .row {
        margin: 0;
        padding: 0;

        .col-lg-7 {
          h1 {
            @media only screen and (max-width: 500px) {
              font-size: 70px;
              letter-spacing: -3.7px;
              margin-top: 40px;
            }

            color: #fff;
            font-family: "Suissie Intl";
            font-size: 150.024px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -10.101px;

            .header-capsule {
              display: flex;

              img {
                @media only screen and (max-width: 500px) {
                  width: 109px;
                  height: 60px;
                }
              }
            }
          }

          .animatedBox {
            opacity: 0;
            transform: translateY(0);
            transition: opacity 1s ease-in-out, transform 0.5s ease-in-out;
          }

          .appear {
            opacity: 1;
            transform: translateY(0);
          }

          .subtitle {
            color: #fff;
            font-family: Montserrat;
            font-size: 26.644px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -1.832px;
            margin-bottom: 100px;

            @media only screen and (max-width: 500px) {
              font-size: 13px !important;
              margin-bottom: 50px;
              letter-spacing: -0.7px;
            }
          }
        }

        .col-lg-5 {
          position: relative;
          display: flex;
          justify-content: center;

          img {
            width: 284.7px;
            height: 431px;

            @media only screen and (max-width: 500px) {
              width: 164px;
              height: 249px;
            }
          }

          .imgLeft {
            position: absolute;
            right: 230px;
            top: 0px;
            z-index: 1;

            @media only screen and (max-width: 500px) {
              right: 44% !important;
              opacity: 0;
            }
          }

          .animatedImgLeft {
            position: absolute;
            right: 165px;
            top: 0px;
            transition: 1s;

            @media only screen and (max-width: 500px) {
              opacity: 1;
            }
          }

          .imgCenter {
            margin: 0;
            padding: 0;
            position: absolute;
            top: -50%;
            right: -170%;
            z-index: 3;
            width: 1924.806px;
            height: 924.806px;
            transform: rotate(28deg);
            opacity: 0.7;

            @media only screen and (max-width: 500px) {
              width: 164px !important;
              height: 249px !important;
              right: 30% !important;
              top: 45px !important;
              opacity: 0;
              transform: rotate(0deg) !important;
              transition: 0.8s;
            }
          }

          .animatedImgCenter {
            position: absolute;
            right: 100px;
            top: 60px;
            z-index: 3;
            transform: rotate(0deg);
            width: 286.866px;
            height: 434.262px;
            opacity: 1;
            transition: 1s;

            @media only screen and (max-width: 500px) {
              opacity: 1;
            }
          }

          .imgRight {
            position: absolute;
            right: -20px;
            top: 100px;
            z-index: 2;

            @media only screen and (max-width: 500px) {
              right: 15% !important;
              top: 80px !important;
              opacity: 0;
            }
          }

          .animatedImgRight {
            position: absolute;
            right: 30px;
            top: 100px;
            z-index: 2;
            transition: 1s;

            @media only screen and (max-width: 500px) {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .card-about {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;

    @media only screen and (max-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  .about {
    position: relative;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #000;
    //opacity: 0;
    //transform: translateY(20px); /* Adjust the initial position as needed */
    //transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

    .our_values_h3 {
      font-size: 86.4px;
      font-weight: 600;

      @media only screen and (max-width: 1000px) {
        font-size: 40px;
      }

      @media only screen and (max-width: 500px) {
        font-size: 25px;
      }
    }

    .our_values_img {
      @media only screen and (max-width: 1000px) {
        width: 60px;
        height: 30px;
      }

      @media only screen and (max-width: 500px) {
        width: 40px;
        height: 21px;
      }
    }

    .our_values_p {
      font-size: 27.36px;
      font-weight: 600;

      @media only screen and (max-width: 1000px) {
        font-size: 20px;
      }

      @media only screen and (max-width: 500px) {
        font-size: 11px;
      }
    }

    .our_values_icon {
      width: auto;
      background: radial-gradient(100% 100% at 0% 0%,
          rgba(255, 255, 255, 0.4) 0%,
          rgba(255, 255, 255, 0) 100%);
      border: 0.97px solid;
      border-radius: 24.14px;
      padding: 20px;
      margin-bottom: 20px;
      border-image-source: linear-gradient(169.15deg,
          rgba(255, 255, 255, 0.4) 0%,
          rgba(238, 237, 237, 0.2) 96.79%);
      box-shadow: -2.41px -2.41px 120.69px 0px #ffffff05 inset;

      @media only screen and (max-width: 1000px) {
        width: 50%;
        padding: 15px;
        border-radius: 15px;
      }

      @media only screen and (max-width: 500px) {
        width: 30%;
        padding: 10px;
        border-radius: 9px;
      }
    }

    .main {
      padding: 50px 0;

      @media only screen and (max-width: 500px) {
        padding: 30px 0;
      }

      .title {
        display: flex;
        align-items: center;
        color: #fff !important;
        font-family: Montserrat;
        font-size: 56.311px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -4.316px;

        @media only screen and (max-width: 500px) {
          font-size: 24px;
          letter-spacing: -1.2px;
          margin-left: 4.5%;
        }

        @media only screen and (min-width: 700px) {
          font-size: 56.311px;
          letter-spacing: -1.2px;
          margin-left: 4.5%;
        }

        .about-capsule {
          display: flex;
          align-items: center;
          margin-right: 10px;

          @media only screen and (max-width: 500px) {
            margin-right: 10px;
          }
        }

        .about-img {
          @media only screen and (max-width: 500px) {
            width: 40px;
            height: 21px;
          }
        }
      }

      .subtitle {
        color: #fff;
        font-family: Montserrat;
        font-size: 23.682px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1.334px;
        margin-top: 72px;

        @media only screen and (max-width: 500px) {
          font-size: 13px;
          letter-spacing: -0.4px;
          margin-top: 18px;
          padding: 20px;
        }
      }

      .row {
        width: 100%;
        height: 800px;
        margin: 50px 0 0;
        padding: 0;

        @media only screen and (max-width: 500px) {
          height: auto;
        }

        .carded {
          border-radius: 35.679px;
          background: #d9d9d9;
          width: 100%;
        }

        .col-lg-4 {
          .card1 {
            width: 100%;
            height: 620px;

            @media only screen and (max-width: 500px) {
              display: none;
            }
          }
        }

        .col-lg-5 {
          @media only screen and (max-width: 500px) {
            display: block;
          }

          .card2 {
            width: 100%;
            height: 300px;

            @media only screen and (max-width: 500px) {
              width: 100%;
              height: 308px;
              display: none;
            }
          }

          .card3 {
            margin-top: 20px;

            @media only screen and (max-width: 500px) {
              width: 100%;
              height: 308px;
              display: none;
            }
          }
        }

        .col-lg-3 {
          @media only screen and (max-width: 500px) {
            display: none;
          }

          .card4 {
            height: 360px;

            @media only screen and (max-width: 500px) {
              width: 100%;
              height: 308px;
              display: none;
            }
          }

          .card5 {
            height: 240px;
            margin-top: 20px;

            @media only screen and (max-width: 500px) {
              width: 100%;
              height: 308px;
              display: none;
            }
          }
        }
      }

      h2 {
        display: flex;
        color: #fff;
        font-family: Montserrat;
        font-size: 111px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -7.809px;
        padding: 20px;
        height: auto;

        @media only screen and (max-width: 500px) {
          font-size: 24px;
          letter-spacing: -2.1px;
          display: flex;
          flex-direction: column;
          margin-top: 5px;
        }

        .rectengle-img {
          display: flex;
          align-items: center;
          margin: 3px;
          margin-bottom: 10px;

          @media only screen and (max-width: 500px) {
            width: 136px;
            height: 27px;
          }

          img {
            @media only screen and (max-width: 500px) {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      .cat2 {
        @media (min-width: 600px) {
          display: none;
          // margin-top: 50px;
        }
      }

      h1 {
        @media only screen and (max-width: 500px) {
          font-size: 24px !important;
          display: flex;
          flex-direction: column;
          padding: 20px;
          // margin-top: 50px;
        }
      }

      .col img {
        @media only screen and (max-width: 500px) {
          width: 50% !important;
        }
      }

      .main.container img {
        @media only screen and (max-width: 500px) {
          width: 100% !important;
        }
      }

      // .flickity-viewport {
      //   @media only screen and (max-width: 500px) {
      //     right: 74px !important;
      //     // width: 119% !important;
      //   }
      // }

      .flickity-slider img {
        @media only screen and (max-width: 500px) {
          width: 85% !important;
        }
      }

      .videoplayer {
        width: 100%;
        margin-top: 30px;

        @media only screen and (max-width: 500px) {
          width: 100%;
          height: auto;
        }
      }
    }

    .about-us {
      background-image: url("../images/background-about-us.png");
      background-position: center;
      padding-bottom: 110px;

      @media only screen and (max-width: 500px) {
        padding-bottom: 40px;
        background-position: top;
        background-size: cover;
      }

      .row {
        padding: 50px 98px 0;
        margin: 0;

        @media only screen and (max-width: 500px) {
          padding: 50px 36px 0;
          display: flex;
          justify-content: center;
        }

        .col-lg-4 {
          display: flex;
          justify-content: center;

          @media only screen and (max-width: 500px) {
            width: 339px;
            height: 423px;
            margin-top: 45px;
          }

          .card-about {
            height: 450px;
            width: 400px;
            border-radius: 50px;
            border: 2px solid #fff;
            background: radial-gradient(107.32% 141.42% at 0% 0%,
                rgba(255, 255, 255, 0.24) 0%,
                rgba(255, 255, 255, 0) 100%);
            box-shadow: -5px -5px 250px 0px rgba(255, 255, 255, 0.02) inset;
            backdrop-filter: blur(21px);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            @media only screen and (max-width: 500px) {
              width: 100%;
              height: auto;
              padding: 0 20px;
            }

            h3 {
              color: #fff;
              font-family: Montserrat;
              font-size: 80.311px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: -4.316px;
              padding-top: 50px;

              @media only screen and (max-width: 500px) {
                font-size: 70px;
                letter-spacing: -3.5px;
              }
            }

            h4 {
              color: #fff;
              font-family: Montserrat;
              font-size: 50.69px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: -2.535px;
              padding-top: 80px;

              @media only screen and (max-width: 500px) {
                font-size: 41px;
                letter-spacing: -2px;
              }
            }

            .subtitle {
              color: #fff;
              text-align: center;
              font-family: Montserrat;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: -1.271px;

              @media only screen and (max-width: 500px) {
                font-size: 20px;
                letter-spacing: -1px;
              }
            }

            img {
              width: 339.167px;
              height: 212px;
              border-radius: 31.667px;
              margin-bottom: 40px;

              @media only screen and (max-width: 500px) {
                width: 100% !important;
                height: 172px;
              }
            }
          }
        }

        .col-lg-12 {
          margin-top: 50px;

          @media only screen and (max-width: 500px) {
            width: 339px;
            height: 381px;
          }

          .card-about {
            width: 100%;
            height: 400px;
            border-radius: 50px;
            border: 2px solid #fff;
            background: radial-gradient(107.32% 141.42% at 0% 0%,
                rgba(255, 255, 255, 0.24) 0%,
                rgba(255, 255, 255, 0) 100%);
            box-shadow: -5px -5px 250px 0px rgba(255, 255, 255, 0.02) inset;
            backdrop-filter: blur(21px);
            padding: 30px 15px;

            @media only screen and (max-width: 500px) {
              display: flex;
              flex-direction: column;
              width: 100%;
              height: auto;
              padding-top: 22px;
              border-radius: 41px;
            }

            .col-lg-6 {
              padding: 0 15px;

              @media only screen and (max-width: 500px) {
                padding: 0;
              }

              .blackSeed {
                @media only screen and (max-width: 500px) {
                  display: none;
                }
              }

              .blackSeed-media {
                display: none;

                @media only screen and (max-width: 500px) {
                  display: block;
                  height: 80px;
                  margin-top: 17px;
                }
              }

              img {
                width: 100%;
                height: auto;
                border-radius: 32.5px;

                @media only screen and (max-width: 500px) {
                  width: 100%;
                  height: 132px;
                  border-radius: 12px;
                }
              }

              .title {
                font-family: Montserrat;
                font-size: 60.54px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -3.027px;

                @media only screen and (max-width: 500px) {
                  font-size: 30px;
                  letter-spacing: -1.5px;
                  margin-bottom: 0;
                }
              }

              .subtitle {
                font-family: Montserrat;
                font-size: 26.242px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -1.312px;
                margin: 0;

                @media only screen and (max-width: 500px) {
                  font-size: 13px;
                  letter-spacing: -0.6px;
                  padding-top: 18px;
                }
              }
            }
          }
        }
      }
    }

    .partners {
      background-image: url("../images/background-partners.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @media only screen and (max-width: 500px) {
        background-position: top left;
        background-repeat: no-repeat;
      }

      .partners-page {
        h2 {
          display: flex;
          color: #fff;
          font-family: Montserrat;
          font-size: 85px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -5.686px;

          @media only screen and (max-width: 500px) {
            font-size: 31px;
            letter-spacing: -1.5px;
            display: flex;
            flex-direction: column-reverse;
          }

          .rectengle-img {
            display: flex;
            align-items: center;
            margin-right: 20px;
            margin-top: 12px;
          }

          img {
            @media only screen and (max-width: 500px) {
              width: 143px;
              height: 27px;
            }
          }
        }

        .row {
          .col-lg-12 {
            margin-top: 50px;

            @media only screen and (max-width: 500px) {
              margin-top: 30px;
            }

            .card-about {
              max-width: 100%;
              height: 500px;
              border-radius: 15px;
              border: 2px solid #fff;
              background: radial-gradient(107.32% 141.42% at 0% 0%,
                  rgba(255, 255, 255, 0.24) 0%,
                  rgba(255, 255, 255, 0) 100%);
              box-shadow: -5px -5px 250px 0px rgba(255, 255, 255, 0.02) inset;
              backdrop-filter: blur(21px);
              padding: 14px;

              @media only screen and (max-width: 500px) {
                width: 100%;
                height: 192px;
                display: flex;
              }

              img {
                height: auto;

                @media only screen and (max-width: 500px) {
                  width: 15%;
                }
              }
            }
          }
        }

        h3 {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-family: Montserrat;
          font-size: 49px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -3.596px;
          background-image: url("../images/oil capsule background.png");
          background-repeat: no-repeat;
          background-position: right;
          background-size: cover;
          width: 600px;
          height: 90px;
          margin: 30px auto 0;
          border-radius: 30px;

          @media only screen and (max-width: 500px) {
            font-size: 26px;
            letter-spacing: -1.3px;
            width: 322px;
            height: 36px;
          }
        }

        .row {
          margin-top: 60px;

          @media only screen and (max-width: 500px) {
            display: flex;
            justify-content: center;
            margin-top: 15px;
          }

          .col-lg-4 {
            display: flex;
            justify-content: center;

            @media only screen and (max-width: 500px) {
              width: 150px;
              height: 121px;
              margin-top: 25px;
            }

            .partners-card {
              width: 284.124px;
              height: 230.129px;
              border-radius: 38.264px;
              border: 1.531px solid #fff;
              background: radial-gradient(107.32% 141.42% at 0% 0%,
                  rgba(255, 255, 255, 0.4) 0%,
                  rgba(255, 255, 255, 0) 100%);
              box-shadow: -3.826px -3.826px 191.319px 0px rgba(255,
                  255,
                  255,
                  0.02) inset;
              backdrop-filter: blur(16.07076644897461px);

              h2 {
                @media only screen and (max-width: 500px) {
                  font-size: 35px !important;
                }
              }

              @media only screen and (max-width: 500px) {
                width: 150px;
                height: 121px;
                border-radius: 20px;
              }

              img {
                @media only screen and (max-width: 500px) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .animatedMain {
      opacity: 1;
      transform: translateY(0);
      transition: 2s;
    }
  }
}

.partners--grid img {
  @media screen and (max-width: 650px) {
    max-width: 45% !important;
    height: auto;
  }
}

.partners--grid2 img {
  @media screen and (max-width: 650px) {
    max-width: 95% !important;
    height: auto;
  }
}

.carousel-cell {
  position: relative;
}

.carousel-overlay {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}


.carousel-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Полупрозрачный черный фон для всего контейнера
  z-index: 1;
  color: #fff;
}

.carousel-text {
  position: absolute;
  top: 50%;
  left: 44%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 22px;
  text-align: center;
  opacity: 0; // Начальное состояние - текст невидим
  transition: opacity 0.5s;
}

@media (max-width: 768px) {
  .carousel-text {
    opacity: 1; // Показать текст только на мобильных устройствах
    z-index: 9;
  }
}