.aboutCompany {
  font-family: Montserrat;
  font-weight: 600;
  letter-spacing: -0.05em;
  color: #fff;
  .header {
    padding: 0 98px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-image: url("../images/about-company-header-img.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media only screen and (max-width: 500px) {
      padding: 0 30px;
      height: 98vh;
      background-size: cover;
      background-position: center;
      background-color: #000000;

    }
    h1 {
      font-size: 204px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: auto;
      margin-bottom: auto;
      @media only screen and (max-width: 500px) {
        font-size: 76px;
        margin-top: 50%;
        text-align: left;
        align-items: flex-start;
        margin-bottom: 0;
      }
      .title {
        .about-company-info {
          font-size: 38px;
          @media only screen and (max-width: 500px) {
            display: none;
          }
        }
      }
      .bottom-title {
        display: flex;
        margin-left: auto;
        transform: translateY(-70px);
        @media only screen and (max-width: 500px) {
          transform: translateY(-30px);
          display: flex;
          align-items: center;
        }
        img {
          margin-right: 39px;
          @media only screen and (max-width: 500px) {
            width: 63px;
            height: 63px;
            margin-right: 10px;
          }
        }
      }
    }
    .subtitle {
      display: none;
      margin-top: 0;
      @media only screen and (max-width: 500px) {
        font-size: 31px;
        text-align: center;
        display: block;
        margin-bottom: auto;
      }
    }
  }
  .slider-item {
    background-color: #000;
    .slider-subtitle {
      width: 80%;
      text-align: center;
      margin: 0 auto;
      padding: 128px 0 105px ;
      font-size: 21px;
      @media only screen and (max-width: 500px) {
        font-size: 13px;
        padding: 41px 0 36px;
      }
    }
    .flickity-enabled {
      display: block;
      height: 654px;
      @media only screen and (max-width: 500px) {
        height: 300px;
        padding-bottom: 30px;
      }
      .carded {
        height: 100% !important;
        @media only screen and (max-width: 500px) {
          height: 100% !important;
          width: 341px;
          margin-right: 14px !important;
        }
      }
      .flickity-prev-next-button {
        width: 40px;
        height: 69px;
        @media only screen and (max-width: 500px) {
          width: 35px;
          height: 55px;
        }
      }
      .previous {
        left: 9%;
      }
      .next {
        right: 9%;
      }
    }
  }
  .main {
    padding: 0 98px;
    background-image: url("../images/about-company-main-img.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media only screen and (max-width: 500px) {
      padding: 0 36px;
    }
    h2{
      @media only screen and (max-width: 500px) {
       font-size: 22px !important;
      }
    }

    .about-command {
      .title {
        font-size: 54px;
        padding: 106px 0 73px;
        @media only screen and (max-width: 500px) {
          font-size: 18px;
          padding: 45px 0 16px;
        }
        img {
          width: 115px;
          height: 63px;
          margin-left: 10px;
          @media only screen and (max-width: 500px) {
            width: 34px;
            height: 18px;
            margin-left: 5px;
          }
        }
      }
      .subtitle {
        font-size: 21px;
        margin-bottom: 80px;
        @media only screen and (max-width: 500px) {
          font-size: 13px;
          margin-bottom: 20px;
        }
      }
      .map {
        width: 95%;
        height: 754px;
        margin: 0 auto;
        background-image: url("../images/about-command-map.png");
        background-repeat: no-repeat;
        background-size: inherit;
        background-position: center;
        position: relative;
        @media only screen and (max-width: 500px) {
          height: 225px;
          background-size: contain;
        }
        .experience,
        .branches,
        .areas,
        .retail-points {
          font-size: 40px;
          background: radial-gradient(107.32% 141.42% at 0% 0%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
          box-shadow: -4.167px -4.167px 208.333px 0px rgba(255, 255, 255, 0.02) inset;
          backdrop-filter: blur(17.5px);
          display: flex;
          align-items: center;
          padding: 2px 10px;
          width: 338px;
          height: 47px;
          border-radius: 16px;
          position: absolute;
          @media only screen and (max-width: 500px) {
            font-size: 11px;
            padding: 2px 7px;
            width: 99px;
            height: 13px;
          }
        }
        .experience {
          top: 6%;
          left: 28%;
        }
        .branches {
          top: 21%;
          left: 48%;
        }
        .areas {
          bottom: 35%;
          left: 13%;
        }
        .retail-points {
          width: 529px;
          bottom: 1%;
          left: 24%;
          margin-bottom: 0;
          @media only screen and (max-width: 500px) {
            width: 150px;
          }
        }
      }
    }
    .charity {
      img {
        width: 100%;
      }
    }
    .key-values {
      .row {
        img {
          width: 100%;
          height: auto;
          @media only screen and (max-width: 500px) {
            height: auto;
            margin-top: 14px;
            border-radius: 23px;
          }
        }
        .col-lg-6 {
          margin: 24px 0;
          @media only screen and (max-width: 500px) {
            margin: 0;
          }
          img {
            height: 100%;
            @media only screen and (max-width: 500px) {
              height: 314px;
              margin-top: 14px;
            }
          }
          .img-adaptive {
            @media only screen and (max-width: 500px) {
              height: auto;
            }
          }
        }
      }
    }
  }
}

.about-company-navbar {
  display: none;
}