.toast-progress-bar {
    background: #f1c56c !important;
}

/* Основной скроллбар */
::-webkit-scrollbar {
    width: 8px; /* Ширина скроллбара */
    background: linear-gradient(0deg, rgb(213, 162, 81) 0%, rgb(47, 37, 18) 97%) !important; /* Цвет фона скроллбара */
  }
  
  /* Скроллбар-ползунок */
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(0deg, rgb(213, 162, 81) 0%, rgb(47, 37, 18) 97%) !important; /* Цвет ползунка скроллбара */
    border-radius: 10px; /* Скругление углов ползунка */
  }
  
  /* Скроллбар-ползунок при наведении */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Цвет ползунка при наведении */
  }
  
  /* Скроллбар-воротник (часть скроллбара, которая не двигается) */
  ::-webkit-scrollbar-track {
    background-color: #fff; /* Цвет фона трека скроллбара */
    border-radius: 10px; /* Скругление углов трека */
  }