.active-basket {
  visibility: visible !important;
  transition: 0.5s;
}

@media only screen and (max-width: 500px) {
  .exit-btn{
    top: 30px;
  }
}

.basket-modal {
  box-shadow: none;
  font-family: Montserrat;
  font-weight: 500;
  display: flex;
  justify-content: center;
  visibility: hidden;

  .basket-dialog {
    max-width: 100%;

    @media only screen and (max-width: 500px) {
      margin: 0 !important;
      padding: 0 !important;
    }

    .basket-content {
      width: 1110px !important;
      padding: 0;
      border-radius: 39px;

      @media only screen and (max-width: 500px) {
        max-width: 100%;
        height: auto;
        border-radius: 0;
      }

      .basket-img {
        height: 400px;
        width: 380px;

        @media only screen and (max-width: 500px) {
          width: 100%;
          display: none;
          img {
            display: flex;
            margin: 0 auto;
            margin-top: 75px;
            margin-bottom: 20px;
          }
        }
      }

      .basket-body {
        @media only screen and (max-width: 500px) {
          height: 100%;
          padding: 16px 13px;
        }

        .row {
          display: flex;
          align-items: center;
          .col-lg-5 {
            @media only screen and (max-width: 500px) {
              padding: 0;
            }

            .basket-img {
              @media only screen and (max-width: 500px) {
                max-width: 100%;
              }

              img {
                width: 100%;
                height: 100%;
                margin-bottom: 14px;
                border-radius: 20px;

                @media only screen and (max-width: 500px) {
                  width: 100%;
                  border-radius: 20px;
                  height: 350px;
                  margin-top: 0;
                }
              }

              .marketing-btn {
                width: 374px;
                height: 71px;
                background: radial-gradient(107.32% 141.42% at 0% 0%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
                box-shadow: -3.68px -3.68px 184.005px 0px rgba(255, 255, 255, 0.02) inset;
                backdrop-filter: blur(15.456403732299805px);
                border-radius: 27px;
                @media only screen and (max-width: 500px) {
                  width: 190px;
                  height: 60px;
                  border-radius: 16px;
                  margin: 30px auto;
                  display: none;
                }

                span {
                  cursor: pointer;
                  font-size: 22px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 2px 17px;

                  @media only screen and (max-width: 500px) {
                    font-size: 15px;
                    padding: 8px 6px;
                  }

                  img {
                    background-image: url("../../../images/footer-arrow-backgound.png");
                    background-repeat: no-repeat;
                    background-size: inherit;
                    background-position: center;
                    border-radius: 50%;
                    width: 47px;
                    height: 47px;
                    padding: 6px;
                    margin-top: 10px;

                    @media only screen and (max-width: 500px) {
                      width: 42px;
                      height: 42px;
                      padding: 7px;
                    }
                  }
                }
              }
            }
          }

          .col-lg-7 {
            padding: 0;

            .basket-about {
              padding: 30px 20px;

              @media only screen and (max-width: 500px) {
                display: flex;
                justify-content: center;
                flex-direction: column;
                padding: 10px;
              }

              .basket-title {
                margin: 0px 0 14px;
                font-weight: 600;
                font-size: 51px;
                color: #CCAD78;

                @media only screen and (max-width: 500px) {
                  font-size: 41px;
                  margin-bottom: 7px;
                  margin-top: 20px;
                }
              }

              .basket-subtitle {
                margin-bottom: 32px;
                font-size: 23px;

                @media only screen and (max-width: 500px) {
                  margin-bottom: 5px;
                  font-size: 18px;
                }
              }

              .value {
                margin-bottom: 5px;
                font-size: 18px;
                display: flex;
                align-items: center;

                .span-btn {
                  display: block;
                  width: 27px;
                  height: 17px;
                  border-radius: 4px;
                  background: rgba(255, 255, 255, 0.17);
                  border: 0.48px solid #fff;
                  font-size: 13px;
                  display: flex;
                  align-items: center;
                  padding-left: 6px;
                  margin-left: 11px;
                  cursor: pointer;
                  -ms-user-select: none;
                  -moz-user-select: none;
                  -webkit-user-select: none;
                  user-select: none;
                }

                .span-active {
                  background: rgba(255, 255, 255, 0.4) !important;
                }
              }

              .amount {
                margin-bottom: 43px;
                align-items: center;

                @media only screen and (max-width: 500px) {
                  margin-bottom: 20px;
                }

                h3 {
                  font-size: 18px;
                  margin: 0;
                }

                .amount-btn {
                  width: 65px;
                  height: 17px;
                  border-radius: 4px;
                  background: rgba(255, 255, 255, 0.17);
                  border: 0.48px solid #fff;
                  font-size: 13px;
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  margin-left: 11px;

                  span {
                    cursor: pointer;
                    -ms-user-select: none;
                    -moz-user-select: none;
                    -webkit-user-select: none;
                    user-select: none;
                  }

                  .btn {}

                  .result {
                    margin: 0;
                    -ms-user-select: none;
                    -moz-user-select: none;
                    -webkit-user-select: none;
                    user-select: none;
                  }
                }
              }

              .input-name,
              .input-number,
              .input-email {
                border-radius: 12px;
                text-indent: 15px;
                margin-bottom: 15px !important;
              }

              .input-name::placeholder,
              .input-number::placeholder,
              .input-email::placeholder {
                font-size: 18px;
                letter-spacing: -0.05em;
                color: #b0b0b0;
              }

              .d-grid {
                width: 90%;
                margin-top: 47px;

                @media only screen and (max-width: 500px) {
                  width: 100%;
                  margin-top: 10px;
                }

                .basket-btn {
                  width: 305px;
                  height: 49px;
                  font-weight: 600;
                  font-size: 28px;
                  letter-spacing: -0.05em;
                  color: #fff !important;
                  box-shadow: none;
                  background-image: url("../../../images/partnership button.svg");
                  background-size: inherit;
                  background-position: center;
                  background-repeat: no-repeat;
                  border-radius: 36px;
                }
              }
            }
          }
        }
      }
    }
  }
}