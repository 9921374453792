@media (max-width: 600px) {
  .hidden-navbar {
    margin-left: -11px;
  }

  .zero-two {
    padding-top: 25%;
  }

  .hidden-navbar.scrolled {
    margin-left: -11px;
  }
}

.photos-containers {
  img {
    margin-left: 25px;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    img {
      width: 100%;
      margin: 0 auto !important;
    }
  }
}

.advantages {
  background-color: #000;

  ._advanteges {
    position: relative;
  }

  ._advantages-link {
    cursor: pointer;
    position: absolute;
    bottom: 40px;
    left: 40px;
    background: radial-gradient(
      107.32% 141.42% at 0% 0%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: -3.68px -3.68px 184.005px 0px rgba(255, 255, 255, 0.02) inset;
    backdrop-filter: blur(15px);
    border-radius: 32px;
    padding: 23px 16px;

    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    color: #ffffff;
    text-decoration: none;
    display: flex;
    align-items: center;
    max-width: 342px;
    border: 1px solid rgba(255, 255, 255, 0.4);
  }

  ._advantages-arrow {
    background: linear-gradient(210deg, #d5a251 0%, black 98%);
    border-radius: 50%;
    display: flex;
    padding: 12px;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1000px) {
    ._advantages-link {
      font-size: 20px;
      padding: 10px;
      left: 10px;
      bottom: 10px;
    }

    ._advantages-arrow {
      width: 30px;
      height: 30px;
      margin-left: 10px;
    }

    ._advantages-arrow img {
      height: 20px;
      width: 20px;
    }
  }

  @media (max-width: 700px) {
    ._advantages-link {
      padding: 5px;
      font-size: 10px;
    }
  }

  .header {
    margin: 0;
    padding: 0 308px;
    box-sizing: border-box;
    background-image: url('../images/advantages-background 3.png');
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;

    @media only screen and (max-width: 500px) {
      padding: 1px 20px;
    }

    h1 {
      color: #fff;
      font-family: Montserrat;
      font-size: 69.754px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -3.488px;
      margin-top: 7px;
      padding-top: 6%;

      @media only screen and (max-width: 500px) {
        font-size: 22px;
        margin-top: 50px;
        letter-spacing: -2px;
      }

      img {
        @media only screen and (max-width: 500px) {
          width: 37px;
          height: 20px;
        }
      }
    }

    .subtitle {
      color: #fff;
      font-family: Montserrat;
      font-size: 24.564px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -1.078px;
      margin-top: 30px;

      @media only screen and (max-width: 500px) {
        font-size: 18px;
        letter-spacing: -0.7px;
        margin-top: 10px;
      }
    }

    .row {
      margin: 50px 0;
      padding-bottom: 30px;

      @media only screen and (max-width: 500px) {
        margin-top: 30px;
      }

      .col-lg-12 {
        margin-bottom: 16px;

        @media only screen and (max-width: 500px) {
          margin-bottom: 0;
        }

        img {
          width: 100%;
          border-radius: 20px;

          @media only screen and (max-width: 500px) {
            width: 100%;
            height: auto;
            border-radius: 18px;
          }
        }
      }

      .col-lg-6 {
        @media only screen and (max-width: 500px) {
          margin-top: 15px;
        }

        img {
          width: 100%;

          @media only screen and (max-width: 500px) {
            width: 100%;
            height: 143px;
            border-radius: 18px;
          }
        }
      }
    }
  }

  main {
    margin: 0;
    padding: 0 98px;
    box-sizing: border-box;

    @media only screen and (max-width: 500px) {
      padding: 0 36px;
    }

    .subtitle {
      color: #fff;
      font-family: Montserrat;
      font-size: 24.564px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -1.078px;
      padding-top: 40px;

      @media only screen and (max-width: 500px) {
        font-size: 17px;
        letter-spacing: -0.7px;
        padding-top: 10px;
      }
    }

    .companies-info {
      width: 100%;
      height: auto;
      border-radius: 30px;
      padding: 10px;
      padding-bottom: 20px;
      background-image: url('../images/background oil grid.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-top: 50px;

      @media only screen and (max-width: 500px) {
        margin-top: 25px;
        width: 100%;
        height: max-content;
        text-align: center;
      }

      .title {
        color: #fff;
        font-family: Montserrat;
        font-size: 146.165px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -7.308px;
        padding: 0 30px;

        @media only screen and (max-width: 500px) {
          font-size: 40px;
          letter-spacing: -4px;
          margin: 0;
        }
      }

      .companies-subtitle {
        color: #fff;
        font-family: Montserrat;
        font-size: 40.764px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -2.038px;
        padding: 0 39px;

        @media only screen and (max-width: 500px) {
          font-size: 17px;
          letter-spacing: -1px;
          margin: 0;
        }
      }

      h3 {
        color: #fff;
        font-family: Montserrat;
        font-size: 95px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -5.111px;
        padding: 0 35px;

        @media only screen and (max-width: 500px) {
          font-size: 22px;
          letter-spacing: -2px;
          padding-bottom: 12px;
        }
      }
    }

    .row {
      margin: 60px 0;

      @media only screen and (max-width: 500px) {
        margin: 25px 0;
      }

      .title-show {
        display: none;

        @media only screen and (max-width: 500px) {
          display: block;
        }
      }

      .title-hide {
        display: block;

        @media only screen and (max-width: 500px) {
          display: none;
        }
      }

      .col-lg-6 {
        h4 {
          color: #fff;
          font-family: Montserrat;
          font-size: 57.863px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -2.743px;
          margin-top: 30px;

          @media only screen and (max-width: 500px) {
            font-size: 18px;
            letter-spacing: -2px;
            margin-top: 10px;
          }

          img {
            @media only screen and (max-width: 500px) {
              width: 34px;
              height: 18px;
            }
          }
        }

        .subtitle {
          padding-top: 13px;
          color: #fff;
          font-family: Montserrat;
          font-size: 21.564px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -1.078px;

          @media only screen and (max-width: 500px) {
            font-size: 13px;
            letter-spacing: -0.7px;
          }
        }

        .half-img {
          border-radius: 38.792px;
          width: 100%;

          @media only screen and (max-width: 500px) {
            border-radius: 18px;
          }
        }
      }
    }
  }

  section {
    margin: 0;
    padding: 0 98px;
    box-sizing: border-box;
    background-image: url('../images/section-background.png');
    height: 100%;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;

    @media only screen and (max-width: 500px) {
      padding: 0 36px;
      background-position: center;
      background-size: inherit;
    }

    h3 {
      padding-top: 50px;
      color: #fff;
      font-family: Montserrat;
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -4.32px;

      @media only screen and (max-width: 500px) {
        font-size: 26px;
        letter-spacing: -1px;
        padding-top: 30px;
      }

      img {
        @media only screen and (max-width: 500px) {
          width: 51px;
          height: 26px;
        }
      }
    }

    .subtitle {
      color: #fff;
      font-family: Montserrat;
      font-size: 21.564px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -1.078px;
      margin: 15px 0 20px;

      @media only screen and (max-width: 500px) {
        font-size: 13px;
        letter-spacing: -0.7px;
        margin: 20px 0 30px;
      }
    }

    .advantages-truck {
      width: 100%;
      height: auto;
      border-radius: 43.641px;

      @media only screen and (max-width: 500px) {
        width: 100%;
        height: auto;
        border-radius: 26px;
      }
    }

    .rectangle {
      width: 350px;
      height: 68px;
      vertical-align: inherit;

      @media only screen and (max-width: 500px) {
        width: 74px;
        height: 14px;
      }
    }

    .workers {
      @media only screen and (max-width: 500px) {
        font-size: 22px;
        margin-top: 30%;
      }
    }

    .workers span {
      @media only screen and (min-width: 700px) {
        font-size: 57px;
        margin-top: 30%;
      }
    }

    .workers p {
      @media only screen and (min-width: 700px) {
        font-size: 30px;
        line-height: normal;
        letter-spacing: -2px;
      }
    }

    .workers p {
      @media only screen and (max-width: 500px) {
        font-size: 16px;
        margin-top: 15px;
      }
    }
  }

  .application-part {
    margin: 0 auto;
    @media (max-width: 500px) {
      max-width: 320px;
    }
  }
}

@media (max-width: 768px) {
  .container .map {
    height: 50vh !important;
    margin-bottom: 20%;
  }
}
