.partners {
  background-color: #000;

  .header {
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: -0.05em;
    color: #fff;
    padding: 0 98px;
    background-image: url("../images/partners-header-img.png");
    background-size: cover;

    @media only screen and (max-width: 500px) {
      padding: 1px 36px;

    }

    h1 {
      font-size: 63px;
      text-align: center;
      margin-bottom: 30px;
      margin-top: 60px;

      @media only screen and (max-width: 500px) {
        font-size: 24px;
        margin-top: 30px;
      }

      img {
        @media only screen and (max-width: 500px) {
          width: 34px;
          height: 22px;
        }
      }
    }

    .partners-logo {
      @media only screen and (max-width: 500px) {}

      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      a {
        @media only screen and (max-width: 500px) {
          width: 16%;
        }

        img {
          @media only screen and (max-width: 500px) {
            width: 100%;
          }
        }
      }
    }

    .title {
      background-image: url("../images/oil capsule background.png");
      background-size: cover;
      background-repeat: no-repeat;
      width: 830px;
      height: 79px;
      font-size: 64px;
      display: flex;
      justify-content: center;
      margin: 75px auto;

      @media only screen and (max-width: 500px) {
        width: 322px;
        height: 36px;
        font-size: 25px;
        margin: 40px auto;
      }
    }

    .row {
      padding-bottom: 64px;
      width: 90%;
      margin: 0 auto;

      &.row-partners {
        display: grid;
        background: radial-gradient(107.32% 141.42% at 0% 0%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
        box-shadow: -4.167px -4.167px 208.333px 0px rgba(255, 255, 255, 0.02) inset;
        backdrop-filter: blur(17.5px);
        border-radius: 38px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

        @media only screen and (max-width: 1200px) {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }

        gap: 20px;

        .partners-card {
          width: 300px;
          height: 230px;
          // background: radial-gradient(107.32% 141.42% at 0% 0%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
          // box-shadow: -4.167px -4.167px 208.333px 0px rgba(255, 255, 255, 0.02) inset;
          // backdrop-filter: blur(17.5px);
          // border-radius: 38px;
          display: flex;
          padding: 8px;
          justify-content: center;
          align-items: center;
        }
      }

      .col-lg-4 {
        display: flex;
        justify-content: center;

        .partners-card {
          width: 284px;
          height: 230px;
          background: radial-gradient(107.32% 141.42% at 0% 0%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
          box-shadow: -4.167px -4.167px 208.333px 0px rgba(255, 255, 255, 0.02) inset;
          backdrop-filter: blur(17.5px);
          border-radius: 38px;
          display: flex;
          justify-content: center;
          align-items: center;

          @media only screen and (max-width: 500px) {
            width: 150px;
            height: 121px;
            border-radius: 20px;
          }

          img {
            width: auto;
            height: auto;

            @media only screen and (max-width: 500px) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .partners-application {
    padding: 0 98px;
    background-color: #000;

    @media only screen and (max-width: 500px) {
      padding: 0 36px;
    }
  }
}

@media (max-width: 500px){
  .hidden-partners-navbar {
    margin-left: -9%;
  }
  .hidden-partners-navbar.scrolled {
        background-color: black;
        margin-left: -9%;
        padding: 10px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    }
}