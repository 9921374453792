.orderActive {
  visibility: visible !important;
}

.order-modal {
  font-family: Montserrat;
  font-weight: 600;
  display: block;
  visibility: hidden;
  .order-dialog {
    @media only screen and (max-width: 500px) {
    }
    .order-content {
      backdrop-filter: blur(50.68089294433594px);
      background: rgba(0, 0, 0, 0.8);
      width: 600px !important;
      height: auto !important;
      padding: 0 !important;
      margin-top: 9%;
      @media only screen and (max-width: 500px) {
        width: 90% !important;
        height: auto;
        margin: 0 auto !important;
        padding: 0px !important;
      }
      .order-body {
        padding: 0 !important;
        .basket-about {
          padding: 75px 65px;
          @media only screen and (max-width: 500px) {
            margin: 0;
            padding: 30px 25px;
          }
          .basket-title {
            margin: 0px 0 14px;
            font-weight: 600;
            font-size: 2rem;
            color: #ccad78;
            @media only screen and (max-width: 500px) {
              font-size: 29px;
              margin-top: 20px;
            }
          }
          .basket-subtitle {
            margin-bottom: 32px;
            font-size: 23px;
            @media only screen and (max-width: 500px) {
              font-size: 12px;
            }
          }
          .value {
            margin-bottom: 5px;
            font-size: 18px;
            display: flex;
            align-items: center;
            @media only screen and (max-width: 500px) {
              font-size: 15px;
            }
            .span-btn {
              display: block;
              width: 27px;
              height: 17px;
              border-radius: 4px;
              background: rgba(255, 255, 255, 0.17);
              border: 0.48px solid #fff;
              font-size: 13px;
              display: flex;
              align-items: center;
              padding-left: 6px;
              margin-left: 11px;
              cursor: pointer;
              -ms-user-select: none;
              -moz-user-select: none;
              -webkit-user-select: none;
              user-select: none;
            }
            .span-active {
              background: rgba(255, 255, 255, 0.4) !important;
            }
          }
          .amount {
            margin-bottom: 43px;
            align-items: center;
            h3 {
              font-size: 18px;
              margin: 0;
              @media only screen and (max-width: 500px) {
                font-size: 15px;
              }
            }
            .amount-btn {
              width: 65px;
              height: 17px;
              border-radius: 4px;
              background: rgba(255, 255, 255, 0.17);
              border: 0.48px solid #fff;
              font-size: 13px;
              display: flex;
              justify-content: space-around;
              align-items: center;
              margin-left: 11px;
              span {
                cursor: pointer;
                -ms-user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                user-select: none;
              }
              .btn {
              }
              .result {
                margin: 0;
                -ms-user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                user-select: none;
              }
            }
          }
          .input-name,
          .input-number,
          .input-email {
            border-radius: 12px;
            text-indent: 15px;
            @media only screen and (max-width: 500px) {
              width: 350px !important;
            }
          }

          .input-name::placeholder,
          .input-number::placeholder,
          .input-email::placeholder {
            font-size: 18px;
            letter-spacing: -0.05em;
            color: #b0b0b0;
          }
          .d-grid {
            width: 90%;
            margin-top: 20px;
            @media only screen and (max-width: 500px) {
              width: 100%;
            }
            .basket-btn {
              width: 305px;
              height: 49px;
              font-weight: 600;
              font-size: 28px;
              letter-spacing: -0.05em;
              color: #fff !important;
              box-shadow: none;
              background-image: url("../../../images/partnership button.svg");
              background-size: inherit;
              background-position: center;
              background-repeat: no-repeat;
              border-radius: 36px;
              @media only screen and (max-width: 500px) {
                width: 250px;
                height: 35px;
                font-size: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}
